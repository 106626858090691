import { graphql } from 'gatsby';
import * as React from 'react';
import ContactInfo from '../components/global/contact-info/contact-info';
import FullWidthImage from '../components/global/full-width-image/full-width-image';
import HeaderAndText from '../components/global/header-and-text/header-and-text';
import Layout from '../components/global/layout/layout';
import PartnerSection from '../components/partner/partner-section/partner-section';

export const pageQuery = graphql`
  query PartnerQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    prismicPartnerPage {
      data {
        title {
          text
        }
        text {
          text
        }
        body {
          items {
            partner_name {
              text
            }
            link {
              url
              target
            }
            partner_description {
              text
            }
          }
          primary {
            heading {
              text
            }
            description {
              text
            }
          }
        }
      }
    }
    prismicHomepage {
      data {
        title_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default props => {
  return (
    <Layout wrapperClassName="bg-pattern-gold"
            title={props.data.prismicPartnerPage.data.title[0].text}
            description={props.data.prismicPartnerPage.data.text[0].text}
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <FullWidthImage
        tag="header"
        fluid={props.data.prismicHomepage.data.title_image.localFile.childImageSharp.fluid}
      />
      <HeaderAndText
        heading={props.data.prismicPartnerPage.data.title[0].text}
        text={props.data.prismicPartnerPage.data.text.map(t => t.text)}
      />
      {
        props.data.prismicPartnerPage.data.body.map((slice, index, arr) => (
          <React.Fragment>
            <PartnerSection slice={slice}/>
            {/* {
              index !== (arr.length - 1) ? (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-8">
                      <hr className="my-5 py-4 border-gold"></hr>
                    </div>
                  </div>
                </div>
              ) : ''
            } */}
          </React.Fragment>
        ))
      }
      <PartnerSection/>
      <ContactInfo/>
    </Layout>
  );
}
