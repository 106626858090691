import React from 'react';
import Styles from './partner-section.module.scss';

export default props => {
    console.log(props)
    if (!props.slice) {
        return '';
    }
    return (
        <section className={`${Styles.section} container pb-5 mb-3`}>
            <div className="row">
                <div className="col-12 mb-3">
                    <h2>{props.slice.primary.heading[0].text}</h2>
                    {
                        props.slice.primary.description.map(p => (
                            <p>{p.text}</p>
                        ))
                    }
                </div>
                {
                    props.slice.items.map(partner => (
                        <div class="col-12 col-md-4 mb-5">
                            <h3>{partner.partner_name[0].text}</h3>
                            {
                                partner.partner_description ? partner.partner_description.map(p => (
                                    <p>{p.text}</p>
                                )) : ''
                            }
                            {
                                partner.link ? (
                                    <a className="stretched-link" href={partner.link.url} target="_blank" rel="noopener nofollow noreferrer">Zur Webseite</a>
                                ) : ''
                            }
                        </div>
                    ))
                }
            </div>
        </section>
    );
}
